const websiteId = process.env.NEXT_PUBLIC_WEBSITE_ID || "nsc";

export const FALLBACK_SEO = {
  title: `${websiteId === "nsc" ? "Nieuw Sociaal Contract" : "Jong Sociaal Contract"}`,
  description:
    "We zijn iets nieuws begonnen: een nieuwe politiek partij. Daarmee gaan we met een mooie groep mensen werken aan uitvoering van onze plannen op het gebied…",
  robots: "index, follow",
};

export const PROVINCES = [
  "Noord-Holland",
  "Zuid-Holland",
  "Utrecht",
  "Zeeland",
  "Overijssel",
  "Gelderland",
  "Flevoland",
  "Drenthe",
  "Noord Brabant",
  "Limburg",
  "Groningen",
  "Friesland",
];
